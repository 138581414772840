* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html, .App {
  height: 100%;
  font-family: 'Arial', sans-serif;
}



.App {
  background-image: url('images/background.jpeg');
  background-size: cover;  
  background-position: center; 
  background-repeat: no-repeat; 
  background-attachment: fixed;
  width: 100%;
  height: 100vh; 
  display: flex; 
  justify-content: center;
  align-items: center; 
}
.coming-soon-container {
  text-align: center;
  color: rgb(0, 0, 0);
  padding: 20px; 
  margin-top: 0; 
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;

}
.progress-bar-container {
  width: 100%; 
  background-color: rgba(77, 78, 76, 0.345); 
  border: 1px solid  white;
  border-radius: 5px;
  margin: 20px auto;
}

.progress-bar {
  height: 10px;
  width: 75%; 
  background-color: orangered; 
  border-radius: 5px; 
  transition: width 0.5s;
}

h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
  color: #004eaf;
}

p {
  font-size: 1.2rem;
  color: #004eaf;
}


.logo {
  position: absolute; 
  top: 2px;
  left: -10px; 
}

.logo img {
  max-width: 100%; 
  height: auto; 
  width: 300px;
 
}
.link-button {
  background-color: #004eaf; 
  color: white;
  border: none; 
  border-radius: 5px; 
  padding: 10px 20px; 
  font-size: 1.2rem; 
  cursor: pointer; 
  transition: background-color 0.3s; 
  margin-top: 20px; 
}

.link-button:hover {
  background-color: #0249a0; 
}




@media (max-width: 1024px) {
  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1rem; 
  }

  .coming-soon-container {
    padding: 20px; 
  }
 
}
@media (max-width: 768px) {
  .App {
    background-size: cover; 
  }

  h1 {
    font-size: 2rem; 
  }

  p {
    font-size: 0.7rem; 
  }

  .logo img {
    width: 40%; 
  }
  .coming-soon-container {
    padding: 10px; 
  }
}
@media (max-width: 480px) {
  .App {
    background-image: url('./images/background-2.jpeg');
  }

  h1 {
    font-size: 1.4rem;
  }

  p {
    font-size: 0.65rem; 
  }

  .logo img {
    width: 40%; 
  }

  .link-button {
    font-size: 0.9rem;  
  }
}